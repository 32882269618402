const lawyerCrow = [
  "You're gay!",
  "Tinybed got a tiny dick lmao",
  "BN lost something when he started to speak",
  "READ THE ROOM HANS",
  "NFTs are the web we weave and people are the flies caught within it",
  "I'm gay",
  "Thread actually thinks he hasn't just given Horsey and Spike $250k for no reason",
  "Hi, Lawyer Crow here. I was going to shit talk piccle here but why bother, he knows what I'm going to say becuase he knows everything. Fuck you piccle. Lawyer Crow OUT!",
  "I miss the future when the Pandas were going to come revive me to revolt against the Cranes. What ever happened, back then in the future?",
];

export const randomLawyerCrow = () => {
  return lawyerCrow[Math.floor(Math.random() * lawyerCrow.length)];
};
